const spanish = {
  //Header Home
  ['The ACLU of Colorado is the state’s oldest and largest civil rights organization. We are a non-partisan organization funded exclusively by the generous donations of our supporters. Our mission is to protect, defend and extend the civil rights and civil liberties of all people in Colorado.']:
    'La Unión Americana de Libertades Civiles de Colorado (ACLU, por sus siglas en inglés) es la organización de derechos civiles más grande y antigua del estado. Somos una organización apartidista financiada exclusivamente por las generosas donaciones de nuestros colaboradores. Nuestra misión es proteger, defender y extender los derechos y libertades civiles de todas las personas de Colorado.',

  //Header File Form
  ['PLEASE BE ADVISED:']: 'POR FAVOR TENGA EN CUENTA',
  ["The purpose of this intake form is for the ACLU of Colorado ('ACLU-CO') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-CO. The ACLU-CO does not represent you unless you and the ACLU-CO have both signed a written retainer agreement."]:
    "el propósito de este formulario de inscripción es que ACLU de Colorado ('ACLU-CO') evalúe si puede proporcionarle asistencia o representación legal. Llenar este formulario no crea una relación abogado-cliente entre usted y ACLU-CO. ACLU-CO no lo está representando legalmente, a menos que usted y ACLU-CO hayan firmado un contrato de prestación de servicios jurídicos",
  ['You are solely responsible']: 'Usted es el único responsable ',
  ['for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.']:
    'de todos los estatutos de limitaciones u otros plazos aplicables a su situación específica. Para proteger sus derechos, por favor consulte con un abogado privado para determinar los plazos aplicables a su caso.',
  ['Do not wait to hear from the ACLU-CO before proceeding with your matter.']:
    'No espere una respuesta de ACLU-CO antes de proceder con su asunto.',
  ['Please be sure to read']: 'Por favor asegúrese de leer sobre',
  ['before filling out this form.']: 'antes de llenar este formulario.',
  ['There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.']:
    'Es posible que existan plazos que podrían afectar su demanda o agravio. Si le preocupa que el tiempo para presentar su reclamo se va a acabar pronto, no debe depender del envío de una solicitud de asistencia legal a ACLU-CO para estar protegido; debe consultar con un abogado de su preferencia.',
  ['If you would like to request legal assistance, fill out the form below.']:
    'Si desea solicitar asistencia legal, llene el formulario a continuación.',

  // Header Form Complete
  ['WE HAVE RECEIVED YOUR COMPLAINT']: 'NOSOTROS HEMOS RECIBIDO SU QUEJA',
  ['Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.']:
    'Desafortunadamente, tenemos recursos muy limitados y no podemos aceptar la mayoría de los casos. Si podemos ayudarlo, nos volveremos a comunicar lo antes posible. Debe continuar buscando otro abogado y tener en cuenta que tiene un período de tiempo limitado para presentar una demanda. Gracias por contactarnos y gracias por su paciencia.',
  ['Return to ACLU Colorado']: 'Regrese a ACLU Colorado',

  //Misc
  ['Language']: 'Idioma',
  ['Submit Request']: 'Enviar solicitud',
  ['Visit ACLU affiliates']: 'Visite otros afiliados de la ACLU',
  ['Indicates the required fields.']: 'Indica los campos requeridos.',
  ['This field is required.']: 'Este campo es requerido.',
  ['Please review the highlighted fields.']: 'Revise los campos resaltados.',
  ['Select']: 'Seleccionar',

  // Titles
  ['ACLU Colorado Online Form To Request Legal Assistance']:
    'Formulario de queja de la ACLU de Colorado',
  ['ACLU COLORADO ONLINE FORM TO REQUEST LEGAL ASSISTANCE']:
    'FORMULARIO EN LÍNEA DE ACLU COLORADO PARA SOLICITAR ASISTENCIA LEGAL',
  ['REQUEST FOR LEGAL ASSISTANCE']: 'SOLICITUD DE ASISTENCIA LEGAL',
  ['THE LOCATION OF THIS INCIDENT']: 'LUGAR DONDE OCURRIÓ EL INCIDENTE',
  ['CONTACT INFORMATION']: 'INFORMACIÓN DE CONTACTO',
  ['ONLINE FORM TO REQUEST LEGAL ASSISTANCE']:
    'FORMULARIO EN LÍNEA PARA SOLICITAR ASISTENCIA LEGAL',
  ['MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)']:
    'MI RECLAMO ES EN CONTRA DE LOS SIGUIENTE (OPCIONAL)',
  ['LAWSUIT INFORMATION (OPTIONAL)']: 'INFORMACIÓN SOBRE LA DEMANDA (OPCIONAL)',
  ['DETAILS OF COMPLAINT']: 'DETALLES DEL RECLAMO',
  ['DISCLAIMER AND NOTICE']: 'ADVERTENCIA Y AVISO',
  ['ATTORNEY INFORMATION (OPTIONAL)']: 'INFORMACIÓN DEL ABOGADO (OPCIONAL)',
  ['AGREEMENT']: 'ACUERDO',

  // Incident Location
  ['Did this incident happen in Colorado?']:
    '¿Se originó su problema en el estado de Colorado?',
  ['Yes']: 'Sí',

  // Contact and Filer Information
  ['Are you filing for yourself or on behalf of someone else?']:
    '¿Está presentando para usted o para otra persona?',
  ['Myself']: 'Usted',
  ['Someone else']: 'Otra persona',
  ['The relationship between you and the affected individual']:
    'La relación entre usted y el individuo afectado.',
  ["Affected Individual's First Name"]: 'Nombre del individuo afectado',
  ["Affected Individual's Last Name"]: 'Apellido del individuo afectado',
  ['First Name']: 'Primer Nombre',
  ['Last Name']: 'Apellido',
  ['Do you have a physical / mailing address?']:
    '¿Tiene una dirección física / postal?',
  ['Address line']: 'Dirección',
  ['Address line 2']: 'Dirección, línea 2',
  ['State']: 'Estado',
  ['City']: 'Ciudad',
  ['Zip Code']: 'Código postal',
  ['Do you have a phone number?']: '¿Tiene un número de teléfono?',
  ['Phone Number']: 'Teléfono',
  ['Mobile Phone Number']: 'Mobile Teléfono',
  ['Do you have an email address?']: '¿Tiene correo electrónico?',
  ['Email']: 'Correo electrónico',
  ['Title']: 'Título',
  ['Preferred Pronouns']: 'Pronombres de preferencia',
  ['She/Her']: 'Ella',
  ['He/Him']: 'Él',
  ['They/Them']: 'Elle',
  ['Other']: 'Otro',
  ['Miss']: 'Señorita',
  ['Ms.']: 'Señora',
  ['Mr.']: 'Señor',
  ['If a prisoner, prison name?']: 'Nombre de la prisión',
  ['Prisoner Number']: 'La número del prisionero',
  ['Ethnic Racial Background']: 'Origen étnico / racial',

  // Complaint Against
  ['Agency']: 'Agencia',
  ['May we contact this person or agency?']:
    '¿Podemos contactar a esta persona o agencia?',

  // ATTORNEY INFORMATION (OPTIONAL)
  ['Are you represented by a lawyer in this matter?']:
    '¿Está representado por un abogado en este asunto?',
  ['Attorney Full Name']: 'Nombre completo del abogado',
  ['Agency Name']: 'Nombre de la agencia',

  // Reported
  ['Have you reported this incident to any other agency?']:
    '¿Usted ha denunciado este incidente a alguna otra agencia?',
  ['If yes, please list all the agency names.']: 'Nombre de la agencia',

  // Incident Information
  ['Date of Incident']: 'Fecha de ocurrencia',
  ['County of Occurrence']: 'Condado donde ocurrió',
  ['Please describe IN DETAIL, all the events that led you to file this complaint.']:
    'Describa EN DETALLE, en un breve resumen, todos los eventos que le llevaron a presentar esta queja.',
  ['State clearly what you would like the ACLU of Colorado to do for you.']:
    'Por favor incluya la mejor resolución que usted quiere.',
  ['Please provide a brief factual account of the events leading you to file this complaint. Please include dates, places and the names of people directly involved.']:
    'Detalles del incidente',
  ['How have you already tried to solve your issue? Please include information about any response from the person/agency you believe violated your rights.']:
    '¿Cómo ya ha intentado resolver su problema?',
  ['What action would you like the ACLU of Colorado to take? How can we help you resolve your problem? Please include the ultimate resolution you would like to see.']:
    '¿Qué medida le gustaría que tomara ACLU de Colorado? ¿Cómo podemos ayudarlo a resolver su problema?',

  // Witness Information
  ['WITNESS INFORMATION (OPTIONAL)']: 'INFORMACIÓN DE TESTIGOS (OPCIONAL)',
  ['May we contact this witness?']: '¿Podemos contactar al testigo?',

  // Lawsuit Information
  ['Please select the date the case was filed.']:
    'Por favor seleccione la fecha en que se presentó el caso',
  ['Firm Name']: 'Nombre de la firma',
  ['Case Name']: 'Número del caso',
  ['Case Number']: 'Nombre del caso',

  // Field Errors
  ['Please enter a valid email address.']:
    'Por favor, introduce una dirección de correo electrónico válida.',
  ['Please enter a valid phone number.']:
    'Por favor ingrese un número de teléfono válido.',
  ['Please enter a valid zip code.']:
    'Por favor ingrese un código postal válido.',

  // Notices
  ['by_checking_this_box']:
    'Al marcar esta caja, acepto permitir que la ACLU de Colorado recopile y use la información que proporciono para evaluar mi solicitud de asistencia legal.',
  ['location_notice']:
    'Por favor proporciónenos más información sobre el estado donde ocurrió este incidente. Si el incidente ocurrió en otro estado, lo redirigiremos a una página donde pueda obtener la información de contacto de un afiliado de ACLU en el estado donde ocurrió el incidente. ACLU de Colorado solo acepta casos que se hayan originado en Colorado.',
  ['affiliate_notice']:
    'If this incident did not arise in the state of Colorado, we cannot help you at this time. Please click the button below to redirect you to the correct ACLU affiliate.',
  ['contact_info_notice']:
    'Información de contacto de la persona afectada por el incidente.',
  ['background_notice']:
    'La siguiente información de antecedentes étnicos /raciales es opcional.  Esto se utiliza para fines internos sólo para proporcionar a la ACLU de Colorado información demográfica sobre las comunidades a las que brinda apoyo en todo Colorado.',
  ['complaint_against_notice']:
    'Por favor ingrese la información acerca de la persona, agencia y/u organización contra la cual está reclamando.',
  ['filer_contact_info_notice']:
    'Información de contacto de la persona que presenta en nombre de la persona afectada.',
  ['incident_notice']:
    'Describa brevemente el incidente que lo llevó a presentar un reclamo ante nuestra oficina. Por favor incluya las fechas, lugares y nombres de las partes directamente involucradas. Indique en su resumen si tiene documentación de apoyo y nos contactaremos con usted de manera separada para obtener copias. Si tiene un enlace a cualquier video relevante, por favor incluya la dirección URL en el resumen.',
  ['witness_notice']:
    'Si tiene un testigo que respalde sus declaraciones, por favor ingrese su información aquí.',
  ['attorney_notice']:
    'Si usted está siendo representado por un abogado en este asunto, por favor proporcione la información aquí.',
  ['lawsuit_notice']:
    'Si se ha entablado una demanda penal o civil en su contra o a su nombre en relación con el reclamo, por favor proporcione la información aquí.',
  ['disclaimer_notice1']:
    'La presente Solicitud no proporciona asesoría legal, por lo que usted no debe usarla como tal. No debe basarse en la información que obtenga en este sitio y debe hablar con un abogado para obtener asesoría en relación con su situación específica. Tampoco podemos prometerle que la información contenida en este sitio esté completa, sea correcta o esté actualizada.',
  ['disclaimer_notice2']:
    'La presente Solicitud no representa una petición o una oferta por parte de la Unión Americana de Libertades Civiles y sus afiliados para representarlo. No podemos prometerle que la información que proporcione produzca cualquier acción específica por parte de la Unión Americana de Libertades Civiles o sus afiliados.',
  ['disclaimer_notice3']:
    'Si usted completa este formulario, acepta que la ACLU de Colorado, la ACLU nacional o sus afiliados, si se identifican como parte del formulario, o uno de sus socios de la coalición puede usar la información que nos proporciona, siempre que no incluya su nombre, dirección, correo electrónico o número de teléfono, para uno o más de los siguientes propósitos: (1) testimonio legislativo, (2) litigio; (3) contactar a una agencia municipal, estatal o federal; o (4) contar su historia al público, incluidos los medios de comunicación. Si la ACLU de Colorado, la ACLU nacional o sus afiliados, o uno de sus socios de la coalición quiere identificarlo, nos pondremos en contacto con usted antes de hacerlo.',
  ['disclaimer_notice4']:
    'Mantendremos la confidencialidad de su nombre, dirección, número de teléfono y correo electrónico, a menos que nos proporcione una autorización para usar dicha información o a menos que un tribunal nos ordene revelarla (aunque, de ser así, intentaremos evitar cualquier tipo de revelación).',
  ['agreement_notice1']:
    'Al hacer clic en el botón “Enviar solicitud”, usted declara que la información que ha proporcionado es verdadera y correcta; que usted entiende que, al aceptar el presente formulario de solicitud, ACLU de Colorado no está aceptando representarlo; y que usted entiende que ACLU de Colorado no es responsable de garantizar que se cumpla cualquier requerimiento de estatuto de limitaciones u otro tipo de plazo pertinente a su caso.',
  ['agreement_notice2']:
    'Mantendremos la confidencialidad de su nombre, dirección, número de teléfono y correo electrónico, a menos que nos proporcione una autorización para usar dicha información o a menos que un tribunal nos ordene revelarla (aunque, de ser así, intentaremos evitar cualquier tipo de revelación).',

  // Checkbox options
  ['American Indian']:
    'Indio Americano / Indígena o Nativo de Alaska',
  ['Asian']:
    'Asiático',
  ['Black']:
    'Negro, Afroamericano',
  ['Hispanic']:
    'Hispano, Latino, Latinx',
  ['Arab']:
    'Árabe, del Medio Oriente o África del Norte',
  ['South Asian']:
    'Sur Asiático',
  ['Native Hawaiian']:
    'Nativo Hawaiano u otro Isleño del Pacífico',
  ['White']:
    'Blanco',
  ['Not listed']:
    'No listado (por favor especifique)'
}

export default spanish
