<template>
  <div class="container-main">
    <div
      v-if="debugging"
      class="debugging-container"
    >
      <pre v-if="JSONData">
      <code>
        {{ JSONData }}
      </code>
      </pre>
      <h1
        style="color: rgb(214, 88, 88);"
      >
        DEBUGGING MODE ENABLED.
      </h1>
      <div
        class="debug-button"
        @click="clearFormData"
      >
        <label class="submit-button-text">
          CLEAR LOCAL DATA
        </label>
      </div>
      <div
        class="debug-button"
        @click="submitForm"
      >
        <label class="submit-button-text">
          TEST SUBMIT
        </label>
      </div>
    </div>
    <FormCompleteHeader
      v-if="submitted === true"
      id="FormCompleteHeader"
      :active-lang="activeLang"
    />
    <FormHomeHeader
      v-else-if="getFormData('incident_in_state') != 'Yes'"
      :active-lang="activeLang"
    />
    <FormFileHeader
      v-else-if="getFormData('incident_in_state') === 'Yes'"
      id="FormFileHeader"
      :active-lang="activeLang"
    />
    <label
      v-if="formError === true"
      id="error-notice"
    >
      {{ lang[activeLang][['Please review the highlighted fields.']] }}</label>
    <Loading v-if="loading" />
    <div v-if="submitted != true && !loading">
      <slot v-for="(formSection, index) in formStructure">
        <form-section
          v-if="
            typeof formSection.shouldShow === 'undefined' ||
              (typeof formSection.shouldShow != 'undefined' &&
                formSection.shouldShow() === true)
          "
          :id="index"
          :key="index"
          :title="index"
          :form-section="formSection"
          :active-lang="activeLang"
          :form-data="formData"
          @formUpdate="formFieldChanged"
        />
      </slot>
      <!--
      <div
        v-if="getFormData('incident_in_state') === 'Yes'"
        id="submit_button"
        class="submit-button"
        @click="submitForm"
      >
        <label class="submit-button-text">
          {{ lang[activeLang]['Submit Request'] }}
        </label>
      </div>
      -->
      <div
        v-if="getFormData('incident_in_state') === 'Yes'"
      >
        <button
          id="submit_button"
          class="submit-button"
          @click="submitForm"
        >
          {{ lang[activeLang]['Submit Request'] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from './FormSection.vue'
import FormHomeHeader from './FormHomeHeader.vue'
import FormFileHeader from './FormFileHeader.vue'
import Loading from './Loading.vue'
import FormCompleteHeader from './FormCompleteHeader.vue'
import states from '../states.js'
//import counties from '../counties.js'
import formRequest from "../api.js"

function isValidEmail(email) {
  // Regular expression taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
  if (!re.test(email)) {
    return false
  }
  return true
}

function isValidPhoneNumber(number) {
  const re = /^(\+\d+)?\s*\(?\d+\)?[\s.-]?\d*[\s.-]?\d*$/
  if (!re.test(number)) {
    return false
  }
  return true
}

function isValidZipCode(zipCode) {
  const re = /^\d{5}(?:[-\s]\d{4})?$/
  if (!re.test(zipCode)) {
    return false
  }
  return true
}

export default {
  name: 'InquiryForm',
  components: {
    FormSection,
    FormHomeHeader,
    FormCompleteHeader,
    Loading,
    FormFileHeader
  },
  props: {
    activeLang: {
      type: String,
      default: localStorage.activeLang || 'English'
    }
  },
  data() {
    return {
      formData: JSON.parse(localStorage.formData || '{}'),
      erroredFields: [],
      JSONData: null,
      submitted: false,
      formError: false,
      debugging: false,
      loading: true,
      formStructure: {}
    }
  },
  async beforeMount() {
    this.loading = true
    try {
      await formRequest.requestFormData()
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  mounted() {
    // Build form inputs here.

    /* 
      PERMISSION TO COLLECT AND USE INFORMATION
    */
    this.addSection('PERMISSION TO COLLECT AND USE INFORMATION', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })

    this.addToRow('PERMISSION TO COLLECT AND USE INFORMATION', 0, 'by_checking_this_box', {
      type: 'checkbox',
      options: ['by_checking_this_box'],
      required: true,
      fullwidth: true
    })

    /* 
      INCIDENT LOCATION
    */
    this.addSection('THE LOCATION OF THIS INCIDENT')

    this.addToRow('THE LOCATION OF THIS INCIDENT', 0, 'location_notice', {
      lines: ['location_notice'],
      type: 'notice'
    })

    this.addToRow('THE LOCATION OF THIS INCIDENT', 1, 'incident_in_state', {
      title: 'Did this incident happen in Colorado?',
      type: 'select',
      options: ['Yes', 'No'],
      required: true,
      fullwidth: true
    })

    this.addToRow('THE LOCATION OF THIS INCIDENT', 2, 'affiliate_notice', {
      lines: ['affiliate_notice'],
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('incident_in_state') === 'No'
      },
    })

    this.addToRow('THE LOCATION OF THIS INCIDENT', 3, 'visit_affiliates', {
      title: 'Visit ACLU affiliates',
      type: 'button',
      url: 'https://www.aclu.org/about/affiliates?redirect=affiliates',
      shouldShow: () => {
        return this.getFormData('incident_in_state') === 'No'
      }
    })


    /* 
      CONTACT INFORMATION
    */
    this.addSection('CONTACT INFORMATION', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') === 'Yes'
      }
    })

    this.addToRow('CONTACT INFORMATION', 0, 'filer_salutation', {
      title: 'Title',
      type: 'select',
      options: ['Dr.', 'Miss', 'Mr.', 'Mrs.', 'Ms.', 'Rev.', 'Prof.', 'Mx.'],
      fullwidth: true
    })

    this.addToRow('CONTACT INFORMATION', 1, 'filer_pronouns', {
      title: 'Preferred Pronouns',
      type: 'select',
      options: formRequest.apiData.get_pronouns.options, 
      fullwidth: true,
    })


    this.addToRow('CONTACT INFORMATION', 2, 'background_notice', {
      lines: ['background_notice'],
      type: 'notice'
    })

    this.addToRow('CONTACT INFORMATION', 2, 'filer_ethnicity', {
      title: 'Ethnic Racial Background',
      type: 'checkbox',
      options: ['American Indian', 'Asian', 'Black', 'Hispanic', 'Arab', 'South Asian', 'Native Hawaiian', 'White', 'Not listed'],
      fullwidth: true
    })
    //options: formRequest.apiData.get_ethnicities.options,

    this.addToRow('CONTACT INFORMATION', 2, 'filer_ethnicity_typed', {
      title: 'Please Specify Your Ethnic/Racial Background',
      type: 'text',
      shouldShow: () => {
        let data = this.getFormData("filer_ethnicity")
        if (!data) return false

        return data.includes && data.includes('Not listed')
      },
      fullwidth: true
    })

    this.addToRow('CONTACT INFORMATION', 3, 'filer_firstname', {
      title: 'First Name',
      type: 'text',
      required: true
    })

    this.addToRow('CONTACT INFORMATION', 3, 'filer_lastname', {
      title: 'Last Name',
      type: 'text',
      required: true
    })

    this.addToRow('CONTACT INFORMATION', 4, 'filer_address_confirm', {
      title: 'Do you have a physical / mailing address?',
      type: 'select',
      options: ['Yes', 'No'],
      fullwidth: true,
      required: true
    })

    this.addToRow('CONTACT INFORMATION', 5, 'filer_address', {
      title: 'Address line',
      type: 'text',
      shouldShow: () => {
        return this.getFormData("filer_address_confirm") == 'Yes'
      }
    })

    this.addToRow('CONTACT INFORMATION', 5, 'filer_address2', {
      title: 'Address line 2',
      type: 'text',
      shouldShow: () => {
        return this.getFormData("filer_address_confirm") == 'Yes'
      }
    })

    this.addToRow('CONTACT INFORMATION', 5, 'filer_city', {
      title: 'City',
      type: 'text',
      required: true,
      shouldShow: () => {
        return this.getFormData("filer_address_confirm") == 'Yes'
      }
    })

    this.addToRow('CONTACT INFORMATION', 5, 'filer_state', {
      title: 'State',
      type: 'select',
      options: states,
      required: true,
      shouldShow: () => {
        return this.getFormData("filer_address_confirm") == 'Yes'
      }
    })

    this.addToRow('CONTACT INFORMATION', 6, 'filer_zip', {
      title: 'Zip Code',
      type: 'text',
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return 'Please enter a valid zip code.'
        }
      },
      shouldShow: () => {
        return this.getFormData("filer_address_confirm") == 'Yes'
      }
    })

    this.addToRow('CONTACT INFORMATION', 7, 'filer_phone_confirm', {
      title: 'Do you have a phone number?',
      type: 'select',
      options: ['Yes', 'No'],
      fullwidth: true,
      required: true
    })

    this.addToRow('CONTACT INFORMATION', 7, 'filer_phone', {
      title: 'Phone Number',
      type: 'text',
      required: true,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return 'Please enter a valid phone number.'
        }
      },
      shouldShow: () => {
        return this.getFormData("filer_phone_confirm") == 'Yes'
      }
    })

    this.addToRow('CONTACT INFORMATION', 8, 'filer_email_confirm', {
      title: 'Do you have an email address?',
      type: 'select',
      options: ['Yes', 'No'],
      fullwidth: true,
      required: true
    })

    this.addToRow('CONTACT INFORMATION', 8, 'filer_email', {
      title: 'Email',
      type: 'text',
      isValid(email) {
        if (!isValidEmail(email)) {
          return 'Please enter a valid email address.'
        }
      },
      shouldShow: () => {
        return this.getFormData("filer_email_confirm") == 'Yes'
      }
    })


    /* 
      MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)
    */
    this.addSection('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })

    
    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 0, 'complaint_against_notice', {
      lines: ['complaint_against_notice'],
      type: 'notice'
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 0, 'against_salutation', {
      title: 'Title',
      type: 'select',
      options: ['Dr.', 'Miss', 'Mr.', 'Mrs.', 'Ms.', 'Rev.', 'Prof.', 'Mx.'],
      fullwidth: true
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 0, 'against_firstname', {
      title: 'First Name',
      type: 'text'
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 0, 'against_lastname', {
      title: 'Last Name',
      type: 'text'
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 1, 'against_agency', {
      title: 'Agency',
      type: 'text',
      fullwidth: true,
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 2, 'against_address', {
      title: 'Address',
      type: 'text'
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 2, 'against_address2', {
      title: 'Address line 2',
      type: 'text'
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 3, 'against_city', {
      title: 'City',
      type: 'text'
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 3, 'against_state', {
      title: 'State',
      type: 'select',
      options: states
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 3, 'against_zip', {
      title: 'Zip Code',
      type: 'text',
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return 'Please enter a valid zip code.'
        }
      }
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 4, 'against_phone', {
      title: 'Phone Number',
      type: 'text',
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return 'Please enter a valid phone number.'
        }
      }
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 4, 'against_email', {
      title: 'Email',
      type: 'text',
      isValid(email) {
        if (!isValidEmail(email)) {
          return 'Please enter a valid email address.'
        }
      }
    })

    this.addToRow('MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)', 4, 'against_affected_permission', {
      title: 'May we contact this person or agency?',
      type: 'select',
      options: ['Yes', 'No']
    })

    /* 
      DETAILS OF COMPLAINT
    */
    this.addSection('DETAILS OF COMPLAINT', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })


    this.addToRow('DETAILS OF COMPLAINT', 0, 'incident_date', {
      title: 'Date of Incident',
      type: 'datepicker',
    })

    this.addToRow('DETAILS OF COMPLAINT', 0, 'incident_county', {
      title: 'County of Occurrence',
      type: 'select',
      options: formRequest.apiData.get_counties.options,
    })
    //options: counties,

    this.addToRow('DETAILS OF COMPLAINT', 1, 'incident_notice', {
      lines: ['incident_notice'],
      type: 'notice'
    })

    this.addToRow('DETAILS OF COMPLAINT', 1, 'incident_reason', {
      title:
        'Please provide a brief factual account of the events leading you to file this complaint. Please include dates, places and the names of people directly involved.',
      type: 'textarea',
      charlimit: 1000,
      required: true
    })

    this.addToRow('DETAILS OF COMPLAINT', 2, 'incident_resolve_attempt', {
      title:
        'How have you already tried to solve your issue? Please include information about any response from the person/agency you believe violated your rights.',
      type: 'textarea',
      charlimit: 1000,
      required: true
    })

    this.addToRow('DETAILS OF COMPLAINT', 3, 'incident_resolution', {
      title:
        'What action would you like the ACLU of Colorado to take? How can we help you resolve your problem? Please include the ultimate resolution you would like to see.',
      type: 'textarea',
      charlimit: 1000,
      required: true
    })

    /*
      WITNESS INFORMATION
    */

    this.addSection('WITNESS INFORMATION (OPTIONAL)', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 0, 'witness_notice', {
      lines: ['witness_notice'],
      type: 'notice'
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 0, 'witness_salutation', {
      title: 'Title',
      type: 'select',
      options: ['Dr.', 'Miss', 'Mr.', 'Mrs.', 'Ms.', 'Rev.', 'Prof.', 'Mx.'],
      fullwidth: true
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 0, 'witness_firstname', {
      title: 'First Name',
      type: 'text'
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 0, 'witness_lastname', {
      title: 'Last Name',
      type: 'text'
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 1, 'witness_agency', {
      title: 'Agency',
      type: 'text',
      fullwidth: true,
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 2, 'witness_address', {
      title: 'Address',
      type: 'text'
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 2, 'witness_address2', {
      title: 'Address line 2',
      type: 'text'
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 3, 'witness_city', {
      title: 'City',
      type: 'text'
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 3, 'witness_state', {
      title: 'State',
      type: 'select',
      options: states
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 3, 'witness_zip', {
      title: 'Zip Code',
      type: 'text',
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return 'Please enter a valid zip code.'
        }
      }
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 4, 'witness_phone', {
      title: 'Phone Number',
      type: 'text',
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return 'Please enter a valid phone number.'
        }
      }
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 4, 'witness_email', {
      title: 'Email',
      type: 'text',
      isValid(email) {
        if (!isValidEmail(email)) {
          return 'Please enter a valid email address.'
        }
      }
    })

    this.addToRow('WITNESS INFORMATION (OPTIONAL)', 4, 'witness_affected_permission', {
      title: 'May we contact this witness?',
      type: 'select',
      options: ['Yes', 'No']
    })    

    /* 
      ATTORNEY INFORMATION (OPTIONAL)
    */
    this.addSection('ATTORNEY INFORMATION (OPTIONAL)', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })

    this.addToRow('ATTORNEY INFORMATION (OPTIONAL)', 0, 'attorney_notice', {
      lines: ['attorney_notice'],
      type: 'notice'
    })

    this.addToRow('ATTORNEY INFORMATION (OPTIONAL)', 0, 'attorney_fullname', {
      title: 'Attorney Full Name',
      type: 'text'
    })

    this.addToRow('ATTORNEY INFORMATION (OPTIONAL)', 0, 'attorney_agency_name', {
      title: 'Agency Name',
      type: 'text'
    })

    this.addToRow('ATTORNEY INFORMATION (OPTIONAL)', 1, 'attorney_phone', {
      title: 'Phone Number',
      type: 'text',
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return 'Please enter a valid phone number.'
        }
      }
    })

    this.addToRow('ATTORNEY INFORMATION (OPTIONAL)', 1, 'attorney_email', {
      title: 'Email',
      type: 'text',
      isValid(email) {
        if (!isValidEmail(email)) {
          return 'Please enter a valid email address.'
        }
      }
    })

    /* 
      LAWSUIT INFORMATION (OPTIONAL)
    */
    this.addSection('LAWSUIT INFORMATION (OPTIONAL)', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })

    this.addToRow('LAWSUIT INFORMATION (OPTIONAL)', 0, 'lawsuit_notice', {
      lines: ['lawsuit_notice'],
      type: 'notice'
    })

    this.addToRow('LAWSUIT INFORMATION (OPTIONAL)', 0, 'lawsuit_file_date', {
      title: 'Please select the date the case was filed.',
      type: 'datepicker',
    })

    this.addToRow('LAWSUIT INFORMATION (OPTIONAL)', 0, 'lawsuit_firm_name', {
      title: 'Firm Name',
      type: 'text'
    })

    this.addToRow('LAWSUIT INFORMATION (OPTIONAL)', 1, 'lawsuit_case_number', {
      title: 'Case Number',
      type: 'text'
    })

    this.addToRow('LAWSUIT INFORMATION (OPTIONAL)', 1, 'lawsuit_case_name', {
      title: 'Case Name',
      type: 'text'
    })

    /* 
      DISCLAIMER AND NOTICE
    */
    this.addSection('DISCLAIMER AND NOTICE', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })

    this.addToRow('DISCLAIMER AND NOTICE', 0, 'disclaimer_notice', {
      lines: [
        'disclaimer_notice1',
        'disclaimer_notice2',
        'disclaimer_notice4'
      ],
      type: 'notice'
    })

    /* 
      AGREEMENT
    */
    this.addSection('AGREEMENT', {
      shouldShow: () => {
        return this.getFormData('incident_in_state') == 'Yes'
      }
    })

    this.addToRow('AGREEMENT', 0, 'agreement_notice', {
      lines: ['agreement_notice1', 'agreement_notice2'],
      type: 'notice'
    })
  },
  methods: {
    formFieldChanged(fieldID, val) {
      // Storing the values into local storage so data is maintained across page navigations.
      this.formData[fieldID] = val
      localStorage.formData = JSON.stringify(this.formData)
      if (fieldID == "incident_in_state") {
        let elmnt = document.getElementById("incident_in_state")
        elmnt.scrollIntoView()
      }
    },
    clearFormData() {
      delete localStorage.formData
    },
    async submitForm() {
      // Make sure the form is valid before properly submitting
      const isValid = this.isFormValid()
      if (!isValid) {
        this.formError = true
        // Loop over all erroredFields and find one that has an error.
        for (let i = 0; i < this.erroredFields.length; i++) {
          let input = this.erroredFields[i]
          if (!input.hasError) { 
            // Remove it from the array since it's no longer relevent
            this.erroredFields.splice(i, 1)
            continue
          }

          // Scroll to errored element for user to see
          let elmnt = document.getElementById(input.index)
          elmnt.scrollIntoView({block: "center"})
          break
        }
      } else {
        this.JSONData = JSON.stringify(this.formData, null, 4)
        const status = await formRequest.submitForm(this.JSONData)
        //console.log(status)
        if (status === 200) {
          //console.log(this.JSONData)
          this.submitted = true
          this.formError = false      
        }
        // Do not delete stored data if debugging.
         if (!this.debugging) delete localStorage.formData
      }
      // This data will be sent via http request.
      //this.JSONData = JSON.stringify(this.formData, null, 4)
      //console.log(this.JSONData)
    },
    addSection(title, sectionData) {
      this.formStructure[title] = sectionData || {}
    },
    addToRow(section, rowID, index, content) {
      content.section = section
      content.index = index
      // Add a content to field to the section if it does not exist already.
      this.formStructure[section].content =
        this.formStructure[section].content || []
      // Create a new row to the section content if it does not exist already.
      this.formStructure[section].content[rowID] =
        this.formStructure[section].content[rowID] || {}
      // Finally add the content to the section, content, row.
      this.formStructure[section].content[rowID][index] = content
    },
    getFormData(index) {
      // Alternative to indexing form data.
      return this.formData[index]
    },
    getInputError(index, input) {
      let showingSection = this.formStructure[input.section].shouldShow

      // If the section is not visible, it is not required to have a value.
      if (showingSection && !showingSection()) return false

      // If the input is not visible, it is not required to have a value.
      let showingInput = input.shouldShow
      if (showingInput && !showingInput()) {
        return false
      }

      // If the input is visible and is not required and is empty we're not gonna perform any valid checks.
      if (!input.required && !this.formData[index]) return false

      // If the input is required and is not inside the formData, then they skipped this input.
      if (!this.formData[index] || this.formData[index] === '') {
        input.hasError = true
        input.requiredError = true
        return true
      }

      // Finally perform valid checks such as email, zip or phone number.
      if (input.isValid) {
        let errorMsg = input.isValid(this.formData[input.index])
        if (errorMsg) {
          input.hasError = true
          input.errorMsg = errorMsg
          return true
        }
      }
    },
    isFormValid() {
      // TODO: Refactor formStructure with a better data structure.
      let isValid = true
      this.errorFields = []
      // eslint-disable-next-line no-unused-vars
      for (let [title, section] of Object.entries(this.formStructure)) {
        for (let inputs of section.content) {
          // console.log(title, inputs, section.content)
          for (let [inputIndex, input] of Object.entries(inputs)) {
            // console.log("INPUT", input)
            // Reset any previous errors when performing the form valid check.
            input.hasError = false
            if (this.getInputError(inputIndex, input)) {
              isValid = false
              this.erroredFields.push(input);
            }
          }
        }
      }
      return isValid
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lang-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#error-notice {
  width: 100%;
  color: rgb(214, 88, 88);
  text-align: left;
  font-size: 16px;
  padding-bottom: 20px;
  float: left;
  text-decoration: underline;
}

.debug-button {
  background-color: rgb(214, 88, 88);
  padding: 10px;
  margin: 20px 25%; 
  cursor: pointer;
  border-radius: 5px;
}

.container {
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

.submit-button {
  background-color: #64b281;
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  padding: 10px 0;
  border-radius: 5px;
  margin: 30px 25% 120px 25%;
  cursor: pointer;
  width: 250px;
}

.submit-button-text {
  pointer-events: none;
}

h1 {
  color: #9cbaf2;
}

label {
  color: #fff;
}
</style>
